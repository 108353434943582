// ** Components **
import BulkUpdateComponent from '../../components/BulkUpdateComponent'

// ** AntD Imports **
import { TableProps, Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

// ** QJE Utils **
import {
  getDefaultCyAttributes,
  isValidURL,
  isValidUrlWithoutHttps
} from '../qje-utils'

// ** Types **
import TransactionLine from '../../../../types/TransactionLine'

// ** Custom Components **
import VirtualSpinner from '../../components/VirtualSpinner'
import { displayTextWidth } from 'src/utils/qje/qje-utils'

const columnsWithCustomRendering = [
  'g_debit',
  'g_credit',
  'transaction_number',
  'g_threshold_support_document',
  'g_reviewed',
  'g_resubmitted',
  'g_is_prepaid',
  'created_at'
]

// ============================================================================
const applyCustomRendering = ({
  qjeColumns,
  isTrdFlagEnabled
}: Record<string, any>) => {
  let qjeColumnsWithCustomRendering: TableProps<TransactionLine>['columns'] =
    qjeColumns
      // Remove hidden columns
      .filter(({ hidden }: Record<string, any>) => !hidden)
      ?.map((item: Record<string, any>) => {
        const apiName: keyof TransactionLine =
          `${item.dataIndex}` as keyof TransactionLine
        let column: Record<string, any> = {
          ...item,
          onCell: (record: any) => ({
            ...getDefaultCyAttributes({
              apiName,
              recordId: record?.id
            }),
            style: {
              backgroundColor: record.backgroundColor
            }
          })
        }

        if (columnsWithCustomRendering.includes(apiName)) {
          column.dataIndex = `custom_${apiName}`
          column.onCell = (record: any) => ({
            ...getDefaultCyAttributes({
              apiName,
              recordId: record?.id
            }),
            className:
              (['g_debit', 'g_credit'].includes(apiName) ||
                item?.data_type === 'decimal') &&
              'text-right',
            rowSpan:
              ['g_reviewed', 'g_resubmitted', 'g_is_prepaid'].includes(
                apiName
              ) && record.rowSpan,
            style: {
              backgroundColor: record.backgroundColor,
              textAlign:
                ['g_resubmitted', 'g_is_prepaid'].includes(apiName) && 'center'
            }
          })
          column.render = (
            value: any,
            record: TransactionLine,
            index: number
          ) => (value === undefined ? <VirtualSpinner /> : value && value) // VirtualSpinner is used only for custom renders
          column.width = ['g_reviewed'].includes(apiName)
            ? 200
            : ['g_resubmitted'].includes(apiName)
            ? 150
            : column.width
        }

        // API names...
        if (apiName === 'g_threshold_support_document') {
          column.render = (value: any, record: TransactionLine) =>
            [undefined].includes(value) ? (
              <VirtualSpinner />
            ) : value &&
              (isValidUrlWithoutHttps(value) || isValidURL(value)) ? (
              <a
                href={
                  value.startsWith('http') || value.startsWith('https')
                    ? value
                    : 'http://' + value
                }
                target='_blank'
                rel='noreferrer'
                style={{ color: '#725bb4' }}
              >
                {value}
              </a>
            ) : (
              value
            )
        } else if (apiName === 'g_reviewed') {
          column.render = (
            value: any,
            record: TransactionLine,
            index: number
          ) =>
            [undefined].includes(value) ? (
              <VirtualSpinner />
            ) : (
              value !== null && (
                <BulkUpdateComponent
                  initialValue={value}
                  row={record}
                  index={index}
                />
              )
            )
        } else if (['g_resubmitted', 'g_is_prepaid'].includes(apiName)) {
          column.render = (
            value: any,
            record: TransactionLine,
            index: number
          ) =>
            [undefined].includes(value) ? (
              <VirtualSpinner />
            ) : (
              value && (
                <CheckOutlined
                  onPointerEnterCapture={() => {}}
                  onPointerLeaveCapture={() => {}}
                />
              )
            )
        }

        // Data types...
        else if (item?.data_type === 'decimal') {
          column.render = (
            value: any,
            record: TransactionLine,
            index: number
          ) => {
            // Check if input is null (NaN)
            if (value && !isNaN(value))
              return Number(parseFloat(value).toFixed(2)).toLocaleString(
                'en-US'
              )
            else return value
          }
        } else {
          //add tooltip if text is truncated
          column.render = (value: any) => {
            if (displayTextWidth(value, column.width) > column.width) {
              return (
                <Tooltip placement='topLeft' title={value}>
                  {value}
                </Tooltip>
              )
            } else {
              return value
            }
          }
        }

        return column
      })
  return qjeColumnsWithCustomRendering
}

export default applyCustomRendering
